<!--
 * @Descripttion : 编辑交车单(办证订单与erp共用)
 * @version      : 
 * @Author       : karl
 * @Date         : 2022-01-07 10:20:38
 * @LastEditors  : karl
 * @LastEditTime : 
-->
<template>
    <div class="publicPage">
        <!-- str -->
        <div class="carInfo">
            <h3>车辆名称：{{detailData.carName||''}}</h3>
            <p>车辆牌照：{{detailData.carPlate||''}}</p>
            <p>VIN码：{{detailData.carVin||''}}</p>
            <p>提车人姓名：{{detailData.pickUpCarRealName||''}}</p>
            <p>联系电话：{{detailData.pickUpCarUserPhone||''}}</p>
        </div>

        <div class="box">
            <h3><span style="color:#d32f2f;margin-right:3px;">*</span>交车证件</h3>
            <div class="bd">
                <div>
                    <a-checkbox v-model="isRegistrationCertificateCheck" @change="onChangeRegistrationCertificate">车辆登记证书</a-checkbox>
                    <a-checkbox v-model="isDrivingPermitCheck" @change="onChangeDrivingPermit">车辆行驶证</a-checkbox>
                    <a-checkbox v-model="isIdentityCardCheck" @change="onChangeIdentityCard">身份证</a-checkbox>
                    <a-checkbox v-model="isDocumentsOtherCheck" @change="onChangeDocumentsOther">其他</a-checkbox>
                    <a-checkbox v-model="isDocumentsNothingCheck" @change="onChangDocumentsNothing">无</a-checkbox>
                </div>
                <a-textarea v-if="isOtherDocumentsText" v-model="form.otherDocumentsText" placeholder="请填写其它备注说明" :rows="3" style="width:100%;max-width:400px;margin-top:15px" allowClear />
            </div>
        </div>

        <div class="box">
            <h3>交车照片</h3>
            <div class="clrfix user_select_none">
                <template v-for="(items,indexs) in fileList">
                    <filesUpload :key="indexs" :fileObj="items" @onChange="uploadDone"></filesUpload>
                </template>
            </div>
        </div>

        <div class="box">
            <h3>交车备注</h3>
            <div class="bd">
                <a-textarea v-model="form.deliverRemark" placeholder="请填写交车备注" :rows="6" style="width:100%;max-width:400px;" allowClear />
            </div>
        </div>

        <div class="box">
            <h3><span style="color:#d32f2f;margin-right:3px;">*</span>二手车销售发票开票金额(元)</h3>
            <div class="bd">
                <a-input-number v-model="form.usedCarInvoicedAmount" placeholder="请输入数值" :precision="0" :min="0" style="width: 260px;" />
            </div>
        </div>

        <div class="box">
            <h3><span style="color:#d32f2f;margin-right:3px;">*</span>车辆安全行驶状态检查</h3>
            <div class="bd">
                <a-checkbox-group v-model="safeDrivingStateDescArr">
                    <a-row style="margin-bottom:10px;" v-for="(item,index) in safeDrivingStateDescAList" :key="index">
                        <a-checkbox :value="item.state+':'+item.desc">{{item.desc}}<span v-if="index<2">，符合上路行驶要求。</span></a-checkbox>
                    </a-row>
                </a-checkbox-group>

                <p><span style="color:#d32f2f;margin-right:3px;">*</span>若车辆不满足上路行驶条件请及时告知办证服务商并采用拖车方式交接车辆。</p>
            </div>
        </div>

        <!--end-->
        <div class="ml_drawer_footer" v-if="isErp!=1">
            <a-button type="primary" @click="onSubmitDrawer" :disabled="isSubmitDisable"> 保存 </a-button>
            <a-button @click="onCloseDrawer"> 取消 </a-button>
        </div>
        <!-- end -->
    </div>
</template>
        
<script>
import {
    getDeliverCarInfo,
    updateDeliverCar,
} from "@/api/pickUpCar";

import filesUpload from "@/components/pickUpCar/filesUpload";

export default {
    name: "pickUpCarEditForm",
    components: {
        filesUpload
    },
    props: {
        orderCurNumber: {
            type: String,
            default: null
        },
        isErp: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            id: "",
            form: {
                carName: "",
                carPlate: "",
                carVin: "",
                certificationOrderNumber: "",
                isDocumentsOther: 0,                    //是否有其他证件(0:否,1:是)
                isDrivingPermit: 0,                     //是否有行驶证(0:否,1:是)
                isIdentityCard: 0,                      //是否有身份证(0:否,1:是)
                isRegistrationCertificate: 0,           //是否有登记证(0:否,1:是)
                isDocumentsNothing: 0,                  //是否无证件(0:否,1:是)
                deliverRemark: "",
                otherDocumentsText: "",
                pickUpManAndCarImage: "",
                documentsImage: "",
                otherImages: [],
                usedCarInvoicedAmount: null,
                safeDrivingStateDesc: '',
                safeDrivingState: '',
            },
            isRegistrationCertificateCheck: false,  //是否有登记证
            isDrivingPermitCheck: false,            //是否有行驶证
            isIdentityCardCheck: false,             //是否有身份证
            isDocumentsOtherCheck: false,           //是否有其他证件
            isOtherDocumentsText: false,            //是否有其他备注
            isDocumentsNothingCheck: false,         //是否无证件
            rules: {
                // carName: [{ required: true, message: "请输入" }],
            },
            isSubmitDisable: false, //阻止重复提交
            orderNumber: '',
            detailData: {},
            fileList: [], //上传图片
            source: '', //来源
            safeDrivingStateDescArr: [],
            safeDrivingStateDescAList: [
                {
                    state: '1',
                    desc: '年检在有效期内'
                },
                {
                    state: '2',
                    desc: '交强险在有效期内'
                },
                {
                    state: '3',
                    desc: '车内燃油满足过户需要'
                },
            ],
        }
    },
    mounted() {
        this.getDetail();
        this.source = this.$route.query.source;
    },
    methods: {
        onSubmitDrawer() {
            const that = this;
            if (that.form.isDocumentsOther == 1) {
                if (!that.form.otherDocumentsText) {
                    that.$message.error("请填写其它备注说明");
                    return false
                }
            } else {
                that.form.otherDocumentsText = '';
            }

            if (!that.isRegistrationCertificateCheck && !that.isDrivingPermitCheck && !that.isIdentityCardCheck && !that.isDocumentsOtherCheck && !that.isDocumentsNothingCheck) {
                if (!that.form.otherDocumentsText) {
                    that.$message.error("请选择交车证件");
                    return false
                }
            }
            else if (!that.form.usedCarInvoicedAmount) {
                that.$message.error("请填写二手车发票交易金额");
                return false
            }
            else {
                that.fileList.forEach(element => {
                    switch (element.code) {
                        case "pickUpManAndCarImage":
                            if (element.uploadList && element.uploadList.length > 0) {
                                that.form.pickUpManAndCarImage = element.uploadList[0];
                            } else {
                                that.form.pickUpManAndCarImage = '';
                            }
                            break;
                        case "documentsImage":
                            if (element.uploadList && element.uploadList.length > 0) {
                                that.form.documentsImage = element.uploadList[0];
                            } else {
                                that.form.documentsImage = '';
                            }
                            break;
                        case "otherImages":
                            if (element.uploadList && element.uploadList.length > 0) {
                                that.form.otherImages = element.uploadList;
                            } else {
                                that.form.otherImages = [];
                            }
                            break;
                    }
                });

                that.form.safeDrivingState = '';
                that.form.safeDrivingStateDesc = '';
                if (that.safeDrivingStateDescArr && that.safeDrivingStateDescArr.length > 0) {
                    let _stateD = '';
                    let _stateDesc = '';
                    that.safeDrivingStateDescArr.forEach((item, index) => {
                        _stateD += item.split(':')[0];
                        _stateDesc += item.split(':')[1];
                        if (index < that.safeDrivingStateDescArr.length - 1) {
                            _stateD += ',';
                            _stateDesc += ',';
                        }
                    })
                    that.form.safeDrivingState = _stateD;
                    that.form.safeDrivingStateDesc = _stateDesc;
                }

                that.loading = true;
                that.isSubmitDisable = true;
                updateDeliverCar(that.form).then(() => {
                    that.loading = false;
                    setTimeout(() => {
                        that.isSubmitDisable = false; //阻止重复提交
                    }, 2000)
                    that.$message.success({
                        content: "操作成功！",
                        duration: 1,
                        onClose: () => {
                            if (that.isErp == 1) {
                                if (that.source == 'plmobile') { //鹏龙拍手机端
                                    window.history.go(-1);
                                } else {
                                    location.href = "carwins://go?back";
                                }
                            } else {
                                that.$emit("refresh");
                                that.$emit("closeDeliverCar");
                            }
                        },
                    });
                }).catch((err) => {
                    that.isSubmitDisable = false; //阻止重复提交
                    console.log('失败：' + err);
                });
            }
        },
        getDetail() {
            const that = this;
            that.loading = true;
            getDeliverCarInfo(that.orderCurNumber, {
                _: new Date().getTime()
            }).then(res => {
                that.loading = false;
                if (res.code == 200 && res.data) {
                    that.detailData = res.data;
                    that.form = res.data;
                    that.form.certificationOrderNumber = that.orderCurNumber;

                    //是否有登记证(0:否,1:是)
                    if (res.data.isRegistrationCertificate == 1) {
                        that.isRegistrationCertificateCheck = true
                    } else {
                        that.isRegistrationCertificateCheck = false
                    }

                    //是否有行驶证(0:否,1:是)
                    if (res.data.isDrivingPermit == 1) {
                        that.isDrivingPermitCheck = true
                    } else {
                        that.isDrivingPermitCheck = false
                    }

                    //是否有身份证(0:否,1:是)
                    if (res.data.isIdentityCard == 1) {
                        that.isIdentityCardCheck = true
                    } else {
                        that.isIdentityCardCheck = false
                    }

                    //是否有其他证件(0:否,1:是)
                    if (res.data.isDocumentsOther == 1) {
                        that.isDocumentsOtherCheck = true
                        that.isOtherDocumentsText = true
                    } else {
                        that.isDocumentsOtherCheck = false
                        that.isOtherDocumentsText = false
                        that.form.otherDocumentsText = '';
                    }

                    //是否无证件(0:否,1:是)
                    if (res.data.isDocumentsNothing == 1) {
                        that.isDocumentsNothingCheck = true
                        //值重置
                        that.form.isRegistrationCertificate = 0
                        that.form.isDrivingPermit = 0
                        that.form.isIdentityCard = 0
                        that.form.isDocumentsOther = 0
                        that.form.otherDocumentsText = '';
                        //排除选中
                        that.isRegistrationCertificateCheck = false
                        that.isDrivingPermitCheck = false
                        that.isIdentityCardCheck = false
                        that.isDocumentsOtherCheck = false
                        that.isOtherDocumentsText = false
                    } else {
                        that.isDocumentsNothingCheck = false
                    }

                    that.fileList = [{
                        name: "提车人与车",
                        code: "pickUpManAndCarImage",
                        fileList: [],
                        uploadList: []
                    },
                    {
                        name: "证件合照",
                        code: "documentsImage",
                        fileList: [],
                        uploadList: []
                    },
                    {
                        name: "其他照片",
                        code: "otherImages",
                        fileList: [],
                        uploadList: []
                    }];

                    if (res.data.pickUpManAndCarImage) {
                        that.fileList[0].fileList.push({
                            uid: 'pickUpManAndCarImage',
                            name: null,
                            status: "done",
                            response: "",
                            url: res.data.pickUpManAndCarImage
                        })
                        that.fileList[0].uploadList.push(res.data.pickUpManAndCarImage)
                    }
                    if (res.data.documentsImage) {
                        that.fileList[1].fileList.push({
                            uid: 'documentsImage',
                            name: null,
                            status: "done",
                            response: "",
                            url: res.data.documentsImage
                        })
                        that.fileList[1].uploadList.push(res.data.documentsImage)
                    }
                    if (res.data.otherImages) {
                        res.data.otherImages.forEach((item, index) => {
                            that.fileList[2].fileList.push({
                                uid: 'otherImages' + (index + 1),
                                name: null,
                                status: "done",
                                response: "",
                                url: item
                            })
                        });
                        that.fileList[2].uploadList = res.data.otherImages
                    }

                    //车辆安全行驶状态检查
                    if (res.data.safeDrivingState) {
                        let _arr = res.data.safeDrivingState.split(',');
                        _arr.forEach(item => {
                            let i = that.safeDrivingStateDescAList.findIndex((value) => value.state == item);
                            that.safeDrivingStateDescArr.push(that.safeDrivingStateDescAList[i].state + ':' + that.safeDrivingStateDescAList[i].desc)
                        })
                    }

                }
            }).catch(err => {
                console.log('失败:' + err);
                that.loading = false;
            });
        },
        onCloseDrawer() {
            this.$emit("closeDeliverCar")
        },
        //登记证
        onChangeRegistrationCertificate() {
            if (this.isRegistrationCertificateCheck) {
                this.form.isRegistrationCertificate = 1
                this.isDocumentsNothingCheck = false //是否无证件
                this.form.isDocumentsNothing = 0
            } else {
                this.form.isRegistrationCertificate = 0
            }
        },
        //行驶证
        onChangeDrivingPermit() {
            if (this.isDrivingPermitCheck) {
                this.form.isDrivingPermit = 1
                this.isDocumentsNothingCheck = false //是否无证件
                this.form.isDocumentsNothing = 0
            } else {
                this.form.isDrivingPermit = 0
            }
        },
        //身份证
        onChangeIdentityCard() {
            if (this.isIdentityCardCheck) {
                this.form.isIdentityCard = 1
                this.isDocumentsNothingCheck = false //是否无证件
                this.form.isDocumentsNothing = 0
            } else {
                this.form.isIdentityCard = 0
            }
        },
        //其他证件
        onChangeDocumentsOther() {
            if (this.isDocumentsOtherCheck) {
                this.form.isDocumentsOther = 1
                this.isOtherDocumentsText = true
                this.isDocumentsNothingCheck = false //是否无证件
                this.form.isDocumentsNothing = 0
            } else {
                this.form.isDocumentsOther = 0
                this.isOtherDocumentsText = false
                this.form.otherDocumentsText = '';
            }
        },
        //交车证件：无
        onChangDocumentsNothing() {
            if (this.isDocumentsNothingCheck) {
                this.form.isDocumentsNothing = 1
                //值重置
                this.form.isRegistrationCertificate = 0
                this.form.isDrivingPermit = 0
                this.form.isIdentityCard = 0
                this.form.isDocumentsOther = 0
                this.form.otherDocumentsText = '';
                //排除选中
                this.isRegistrationCertificateCheck = false
                this.isDrivingPermitCheck = false
                this.isIdentityCardCheck = false
                this.isDocumentsOtherCheck = false
                this.isOtherDocumentsText = false
            } else {
                this.form.isDocumentsNothing = 0
            }
            console.log(this.form.isDocumentsNothing)
        },
        uploadDone(e) {
            const that = this;
            let uploadImgList = [];
            e.fileList.forEach(element => {
                uploadImgList.push(element.url);
            });
            that.fileList.forEach(item => {
                if (item.code == e.fileObj.code) {
                    //   获取修改的类型
                    item.uploadList = uploadImgList;
                }
            });
        },
        onChangeCheckbox(checkedValues) {
            console.log(JSON.stringify(checkedValues), typeof (checkedValues));
            // console.log(JSON.stringify(this.value));
        },

    },
}
</script>

<style lang="scss">
.user_select_none * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
<style lang="scss" scoped>
.carInfo {
    padding: 15px;
    background-image: linear-gradient(223deg, #fbe9d5 14%, #ffcfad 66%);
    margin-bottom: 15px;
    h3 {
        font-size: 16px;
        color: #332d29;
        font-weight: bold;
        margin: 0;
    }
    p {
        color: #666666;
        font-size: 14px;
        margin: 10px 0 0 0;
    }
}
.box {
    padding: 15px;
    h3 {
        font-size: 14px;
        color: #332d29;
        font-weight: bold;
        margin: 0 0 15px 0;
    }
}
.clrfix:after {
    display: table;
    content: " ";
    clear: both;
}

.clrfix {
    clear: both;
}
.ant-checkbox-wrapper {
    margin: 0 8px 0 0;
}
</style>