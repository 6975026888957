/*
 * @Descripttion : 文件上传
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-08 16:44:24
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 20:44:31
 */

import request from './request'

// 根据类型获取上传文件上传设置
export function getFileConfig(type) {
	return request.fetch('fileUploadSet/' + type)
}

// 获取上传文件上传设置
export function getFileConfigAll() {
	return request.fetch('fileUploadSet/all')
}

// 获取上传文件详情
export function getOrderFile(orderNumber) {
	return request.fetch('certificationOrders/getOrderFile/' + orderNumber)
}

// 获取上传文件详情ERP
export function getExternalOrderFile(orderNumber) {
	return request.fetch('certificationOrders/getExternalOrderFile/' + orderNumber)
}


// 文件上传
export function upLoadFile(data) {
	return request.post('files', data)
}

// 文件上传上传
export function uploadFilelimit(data) {
	return request.post('files/uploadFilelimit', data)
}

// 文件上传删除
export function deleteFile(id) {
	// return request.delete('files/' + id)
	return request.post('files/' + id)
}
