<!--
 * @Descripttion : 文件上传组件(单文件/多文件)
 * @version      : 
 * @Author       : karl
 * @Date         : 2022-01-07 10:20:38
 * @LastEditors  : karl
 * @LastEditTime : 
-->
<template>
    <div :class="fileObj.code=='otherImages'?'other-item': 'item'">
        <a-upload :customRequest="customRequest" list-type="picture-card" :file-list="fileList" :headers="headers" :before-upload="beforeUpload" @preview="handlePreview" :remove="removeFile" accept="image/png,image/jpg,image/jpeg">
            <template v-if="fileObj.code=='otherImages'">
                <div v-if="fileList.length <10">
                    <a-icon type="plus" />
                    <!-- 其他照片 -->
                    <div class="ant-upload-text">{{fileObj.name}}</div>    
                </div>
            </template>
            <template v-else>
                <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">{{fileObj.name}}</div>
                </div>
            </template>
        </a-upload>

        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" centered>
            <div style="padding-top:15px;">
                <img alt="" style="width: 100%" :src="previewImage" />
            </div>
        </a-modal>

    </div>
</template>
<script>
import {
    upLoadFile,
} from "@/api/fileUpload";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const token = localStorage.getItem("cbz_token");

export default {
    props: {
        fileObj: {
            type: Object,
            default: null
        },
        orderNumber: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            uploadUrl: process.env.VUE_APP_BASE_API + "files",
            headers: { token: token },
            previewVisible: false,
            previewImage: "",
            fileList: [],
            uploadList: [],
            otherId: 0
        };
    },
    mounted() {
        this.fileList = this.fileObj.fileList;
    },
    methods: {
        customRequest(file) {
            const that = this;
            var formData = new FormData();
            formData.append("file", file.file);
            formData.append("success_action_status", 201); //成功后返回的操作码
            file.onProgress();
            upLoadFile({
                file: file.file,
                fileCode: file.filename,
            }).then(res => {
                if (res.data != null) {
                    that.otherId += 1;
                    let _file = {
                        uid: (that.fileObj.code == 'otherImages' ? that.fileObj.code + that.otherId : that.fileObj.code),
                        name: file.file.name,
                        status: "done",
                        response: "",
                        url: res.data
                    };

                    that.fileList.push(_file);
                    //console.log(that.fileList);
                    file.onSuccess(); //上传成功

                    that.$emit("onChange", {
                        fileObj: that.fileObj,
                        fileList: that.fileList
                    });
                } else {
                    that.$message.error("文件上传失败！");
                }
            }).catch(() => {
                file.onError(); //上传失败
            });
        },
        removeFile(file) {
            const that = this;
            let i = that.fileList.findIndex((value) => value.url == file.url);
            that.fileList.splice(i, 1)
            that.$emit("onChange", {
                fileObj: that.fileObj,
                fileList: that.fileList,
                uploadList: that.uploadList,
            });
            that.$message.success("删除成功");
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({ file, fileList }) {
            // 上传结束
            if (file.status) {
                this.fileList = fileList;
            }

            // console.log(fileList);
            // 状态有：uploading done error removed
            if (file.status == "done" || file.status == "removed") {
                if (file.response && file.response.data.indexOf("http") == -1) {
                    this.fileList.pop();
                }
                this.$emit("onChange", {
                    fileObj: this.fileObj,
                    fileList
                });
            }
        },
        handleCancel() {
            this.previewVisible = false;
        },
        beforeUpload(file) {
            const that = this;

            // console.log(file.size)
            const isLtSize = file.size / 1024 < 20480;

            if (!isLtSize) {
                this.$message.error(
                    "上传文件不能超过" + 20480 / 1024 + "MB!"
                );
                return false;
            }
            return isLtSize;
        }
    }
};
</script>
<style lang="scss">
.mod-box-bd,
.other-item {
    padding: 6px 0 0 0 !important;
    clear: both;
}
.item .ant-upload-list-picture-card-container,
.item .ant-upload-list-item,
.item .ant-upload-select-picture-card,
.other-item .ant-upload-list-picture-card-container,
.other-item .ant-upload-list-item,
.other-item .ant-upload-select-picture-card {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
    border-radius: 4px;
    overflow: hidden;
    float: left;
}
.item p,
.other-item p {
    font-size: 12px;
    color: #666666;
    text-align: center;
    line-height: 12px;
    font-weight: 400;
    padding: 6px 0 0 0;
    background-color: #fff;
    margin: 0;
}
.item .anticon-delete,
.other-item .anticon-delete {
    margin-left: 25px !important;
}
.item .anticon-plus,
.other-item .anticon-plus {
    font-size: 20px;
}
.item .ant-upload-text,
.other-item .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
.item .ant-upload-text,
.other-item .ml_upload_tips {
    font-size: 12px;
}
.item .ant-upload {
    padding: 0 !important;
}
.other-item .ant-upload-select-picture-card {
    border: 1px solid #e9e9e9;
}
//设置容器宽高
.item .ant-upload-list-picture-card-container,
.item .ant-upload-select-picture-card,
.other-item .ant-upload-list-picture-card-container,
.other-item .ant-upload-select-picture-card {
    width: 30vw;
    height: 22.5vw;
    max-width: 112px;
    max-height: 84px;
}
.item {
    float: left;
    // margin: 2.5vw 0 0 2.5vw !important;
    margin: 10px 0 0 10px !important;
}
.other-item .ant-upload-list-picture-card-container,
.other-item .ant-upload-select-picture-card {
    // margin: 2.5vw 0 0 2.5vw !important;
    margin: 10px 0 0 10px !important;
}
</style>