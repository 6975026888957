/*
 * @Descripttion : 办证提车 - erp与办证系统
 * @version      :
 * @Author       : karl
 * @Date         : 2022-01-04 10:16:50
 * @LastEditors  : 
 * @LastEditTime : 
 */
import request from './request'

// 获取提车指派详情
export function getPickUpCar(orderNumber) {
    return request.post('pickUpCar/getPickUpCar/' + orderNumber)
}

// 提车指派(新增/编辑)
export function savePickUpCar(data) {
    data["json"] = true;
    return request.post('pickUpCar/savePickUpCar', data)
}

// 获取PC办证提车详情
export function getPCDetails(orderNumber) {
    return request.fetch('pickUpCar/getPCDetails/' + orderNumber)
}

// 确认提车(办证提车详情)
export function confirmPickUpCar(data) {
    data["json"] = true;
    return request.post('pickUpCar/confirmPickUpCar', data)
}

// 获取编辑交车单信息
export function getDeliverCarInfo(orderNumber) {
    return request.fetch('pickUpCar/getDeliverCarInfo/' + orderNumber)
}

// 编辑交车单
export function updateDeliverCar(data) {
    data["json"] = true;
    return request.post('/pickUpCar/updateDeliverCar', data)
}


